import { Anchor } from "grommet";
import Cookies from "js-cookie";
import { detect } from "../../node_modules/detect-browser";

import {
  helpUrl,
  headerFooterScriptUrl,
  translationsUrl,
  supportedLanguagesListUrl,
  embargoCountryListUrl,
  stylesUrl,
  deepLinkApplicationsListUrl,
  helpUrlCommon,
  helpURL_dev_itg,
  helpURL_prod,
  chinaPolicyLinkChinese,
  chinaPolicyLinkEnglish,
} from "./urls";

export const getLangCookieDetails = (skipPriorityOneCookie, list) => {
  if (!list || !Array.isArray(list)) return "en";
  let lang = "";
  let cc = "";
  let shouldUpdateSelectedLang = true;
  let isBrowserDefaultLang = false;
  if (!skipPriorityOneCookie) {
    lang = Cookies.get("hpeIamLang");
    lang = lang?.toLowerCase();
    if (lang && !list.includes(formatLang(lang))) lang = "";
  }
  
  if (!lang) {
    lang = Cookies.get("lang");
    cc = Cookies.get("cc");
    if (lang && !(lang.includes("-") || lang.includes("_"))) {
      if (lang && cc) {
        lang = `${lang}_${cc}`;
      }
    }
    lang = lang?.toLowerCase();
    if (lang && !list.includes(formatLang(lang))) lang = "";
  }
  
  if (!lang) {
    isBrowserDefaultLang = true;
    const browserLangs = navigator.languages;
    if (browserLangs.length) {
      lang = browserLangs.find(el => list.includes(formatLang(el)))
      lang = lang?.toLowerCase();
      if (lang?.includes("zh")) {
        lang = getChineseLang(lang);
      } else lang = formatLang(lang);
      if (!lang) lang = "en";
    } else {
      lang = "en";
    }
  }

  lang = lang?.toLowerCase();

  if (lang?.includes("zh")) {
    lang = getChineseLang(lang);
  }

  if (lang === "en_us" || lang === "en-us") { 
    lang = "en";
  }

  return { lang, shouldUpdateSelectedLang, isBrowserDefaultLang };
};

export const formatLang = (lang) => {
  if (lang.includes("_") || lang.includes("-")) {
    const res = getLanguageAndCountryCode(lang);
    return res._lang;
  } else return lang;
};

export const getSupportedLanguagesJson = async () => {
  const url = supportedLanguagesListUrl;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (!data) return;
    return data;
  } catch (err) {
    return err;
  }
};

export const getEmbargoCountryList = async () => {
  const url = embargoCountryListUrl;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (!data) return;
    return data;
  } catch (err) {
    return err;
  }
};

export const getJSONDataFromMessagesBucket = async (url) => {
  if (!url) return;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (!data) return;
    return data;
  } catch (err) {
    return err;
  }
};

export const filterLanguage = (langDetails) => {
  if (!langDetails) return;
  const supportedLanguages = [
    "en",
    "fr",
    "pt",
    "de",
    "ru",
    "es",
    "it",
    "ja",
    "ko",
    "zh",
    "tr",
    "id",
  ];
  let lang = langDetails.toLowerCase().trim();
  if (lang.includes("_") || lang.includes("-")) {
    let langWithLocale = [];
    if (lang.includes("_")) langWithLocale = lang.split("_");
    if (lang.includes("-")) langWithLocale = lang.split("-");
    let hpeLang = langWithLocale[0];
    if (!supportedLanguages.includes(hpeLang)) return "en";
    return hpeLang;
    // if (!supportedLanguagesWithCountryCode.includes(lang)) lang = `${hpeLang}`;
  } else {
    if (!supportedLanguages.includes(lang)) return "en";
  }
  return lang;
};

// export const getTranslations = async (langDetails) => {
//   if (!langDetails) return;
//   const url = `${hpeDomainLoginUrl}/messages`;
//   const lang = filterLanguage(langDetails);
//   if (!lang) return;
//   try {
//     const res = await fetch(`${url}/${lang}.json`);
//     return await res.json();
//   } catch (err) {
//     return err;
//   }
// };

export const getHelpUrl = (lang) => {
  let _lang = lang;
  if (lang.includes("_") || lang.includes("-")) {
    const res = getLanguageAndCountryCode(lang);
    _lang = res._lang;
  }
  if (!_lang || _lang === "en") {
    return `${helpUrl}/help.html`;
  }

  return `${helpUrl}/${_lang}/help.html`;
};

export const isLocalDev = () =>
  window.location.origin.includes("http://localhost:300");

export const formatAnchorTag = (message, label, url) => {
  if (!message.includes("<a>")) return message;
  return (
    <div>
      {message.slice(0, message.indexOf("<a>"))}{" "}
      <Anchor label={label} href={url} target="_blank" />
      {message.slice(message.indexOf("</a>") + 4)}
    </div>
  );
};

export const getTranslations = async (lang) => {
  const url = translationsUrl;
  if (!lang) return;
  lang = lang.toLowerCase();

  return await fetch(`${url}/${lang}.json`)
    .then((res) => res.json())
    .then((data) => ({ data, lang }))
    .catch((err) => {
      let _lang;
      if (lang.includes("_") || lang.includes("-")) {
        const res = getLanguageAndCountryCode(lang);
        _lang = res._lang;
      } else return;

      return fetch(`${url}/${_lang}.json`)
        .then((res) => res.json())
        .then((data) => ({ data, _lang }));
    });
};

export const getLanguageAndCountryCode = (lang) => {
  let langWithLocale;
  if (lang.includes("_")) langWithLocale = lang.split("_");
  if (lang.includes("-")) langWithLocale = lang.split("-");
  let _lang = "";
  let _cc = "";
  if (Array.isArray(langWithLocale)) {
    _lang = langWithLocale[0];
    _cc = langWithLocale[1];
  }
  return { _lang, _cc };
};

export const restrictedCountrycheck = (countryList, countryCode) => {
  if (!countryCode || !countryList) return;
  if (countryList.includes(countryCode)) {
    return true;
  }
  return false;
};

export const setRegistrationCookie = (
  selectedLang,
  selectedCountry,
  cookie_domain
) => {
  selectedLang = selectedLang?.toLowerCase();
  selectedCountry = selectedCountry?.toLowerCase();
  let lang = Cookies.get("lang");
  let cc = Cookies.get("cc");
  langCookieUpdate(lang, selectedLang, selectedCountry);
  if (selectedCountry && selectedCountry !== cc)
    ccCookieUpdate(selectedCountry);
  if (selectedLang) {
    iamCookieUpdate(selectedLang, selectedCountry);
    oktaCookieUpdate(selectedLang, cookie_domain);
  }
};

export const langCookieUpdate = (lang, selectedLang, selectedCountry) => {
  if (!lang || lang.includes("-") || lang.includes("_")) {
    document.cookie = `lang=${selectedLang}-${selectedCountry};path=/;secure=true;${
      isLocalDev() ? "domain=localhost" : "domain=.hpe.com"
    }`;
    return;
  }
  document.cookie = `lang=${selectedLang};path=/;secure=true;${
    isLocalDev() ? "domain=localhost" : "domain=.hpe.com"
  }`;
};

export const ccCookieUpdate = (selectedCountry) => {
  document.cookie = `cc=${selectedCountry};path=/;secure=true;${
    isLocalDev() ? "domain=localhost" : "domain=.hpe.com"
  }`;
};

export const iamCookieUpdate = (selectedLang, selectedCountry) => {
  document.cookie = `hpeIamLang=${selectedLang}_${selectedCountry};path=/;secure=true;${
    isLocalDev() ? "domain=localhost" : "domain=.hpe.com"
  }`;
};

export const oktaCookieUpdate = (selectedLang, cookie_domain) => {
  const oktaUserLang = Cookies.get('okta_user_lang');
  if (oktaUserLang) Cookies.remove('okta_user_lang');
  if (cookie_domain === "dev")
    document.cookie = `okta_user_lang=${selectedLang};path=/;secure=true;${
      isLocalDev() ? "domain=localhost" : "domain=auth-dev.hpe.com"
    }`;
  if (cookie_domain === "itg")
    document.cookie = `okta_user_lang=${selectedLang};path=/;secure=true;domain=auth-itg.hpe.com`;
  if (cookie_domain === "prod")
    document.cookie = `okta_user_lang=${selectedLang};path=/;secure=true;domain=auth.hpe.com`;
};

export const setLoginCookie = (
  selectedLang,
  selectedCountry,
  cookie_domain
) => {
  selectedLang = selectedLang?.toLowerCase();
  selectedCountry = selectedCountry?.toLowerCase();
  let lang = Cookies.get("lang");
  let cc = Cookies.get("cc");
  if (selectedLang) {
    iamCookieUpdate(selectedLang, selectedCountry);
    oktaCookieUpdate(selectedLang, cookie_domain);
  }
  if (lang && cc) return;
  langCookieUpdate(lang, selectedLang, selectedCountry);
  if (selectedCountry && selectedCountry !== cc)
    ccCookieUpdate(selectedCountry);
};

export const insertTranslatedHeaderFooter = (
  langWithLocale,
  supportedLanguagesList = [],
  supportedHeaderFooterList
) => {
  if (!langWithLocale || !supportedLanguagesList.length) return;
  let lang = langWithLocale;
  let cc;
  if (langWithLocale.includes("_") || langWithLocale.includes("-")) {
    const { _lang, _cc } = getLanguageAndCountryCode(langWithLocale);
    lang = _lang;
    cc = _cc;
  }

  if (!cc) {
    const langDetails = supportedLanguagesList.find((el) => el.value === lang);
    if (!langDetails) return;
    cc = langDetails?.headerFooterLocale.cc;
  }

  let headerFooterLocale = `${cc}/${lang}`;
  if (!supportedHeaderFooterList.includes(headerFooterLocale)) {
    const langDetails = supportedLanguagesList.find((el) => el.value === lang);
    if (!langDetails) return;
    lang = langDetails?.headerFooterLocale.value;
    cc = langDetails?.headerFooterLocale.cc;
  }

  headerFooterLocale = `${cc}/${lang}`;
  if (!supportedHeaderFooterList.includes(headerFooterLocale)) return;

  const script = document.createElement("script");
  script.src = headerFooterScriptUrl(cc, lang);
  script.async = true;
  document.body.appendChild(script);
};

export const insertDynamicStyle = () => {
  let head = document.head;
  let link = document.createElement("link");
  link.type = "text/css";
  link.rel = "stylesheet";
  link.href = stylesUrl;
  head.appendChild(link);
}

export const checkUrlEncode = (url) => {
  const decodeUrlFirst = decodeURIComponent(url);
  const decodeUrlSecond = decodeURIComponent(decodeUrlFirst);
  const decodeUrlThird = decodeURIComponent(decodeUrlSecond);
  if (decodeUrlThird === url) return encodeURIComponent(url);
  return url;
};

export const encodeURL = (url) => {
  if (url.startsWith("%") || url.startsWith("https%")) return url;
  return encodeURIComponent(url);
};

export const decodeURL = (url) => {
  if (url.startsWith("%") || url.startsWith("https%"))
    return decodeURIComponent(url);
  return url;
};

export const appendFromURI = () => {
  const fromURIValue = localStorage.getItem("fromURI");
  if (!fromURIValue) {
    return "/";
  }
  return `/?fromURI=${encodeURIComponent(fromURIValue)}`;
};

export const openHelpPage = (helpUrl) => {
  window.HPEHF_CFG = {
    customHelpIconHandler: function () {
      window.open(helpUrl, "_blank");
    },
  };
};

export const openHelpIcon = (helpUrl) => {
  if (window.HPEHF && window.HPEHF.hfInitialized) {
    window.HPEHF.registerCustomHelpIconHandler(function () {
      window.open(helpUrl, "_blank");
    });
  } else {
    document.addEventListener("HPEHF.READY", () => {
      if (window.HPEHF && window.HPEHF.hfInitialized) {
        window.HPEHF.registerCustomHelpIconHandler(function () {
          window.open(helpUrl, "_blank");
        });
      }
    });
  }
};

export const filterLanguageAndCountryCode = (list = [], selectedLang) => {
  if (!list.length || !selectedLang) return { lang: "en", cc: "us" };
  const langDetails = list.find(
    (el) => el.value.toLowerCase() === selectedLang.toLowerCase()
  );
  if (!langDetails) return { lang: "en", cc: "us" };
  return {
    lang: langDetails?.privacyAndTermsOfUseLocale?.value || "en",
    cc: langDetails?.privacyAndTermsOfUseLocale?.cc || "us",
  };
};

export const activateRedirectCookieUpdate = (cookie) => {
  // const expires = cookieExpirationInDays(8);
  // if (cookie_domain === "dev")
  //   document.cookie = `activationRedirect=${relayState};expires=${expires};path=/;secure=true;${
  //     isLocalDev() ? "domain=localhost" : "domain=auth-dev.hpe.com"
  //   }`;
  // if (cookie_domain === "itg")
  //   document.cookie = `activationRedirect=${relayState};expires=${expires}path=/;secure=true;domain=auth-itg.hpe.com`;
  // if (cookie_domain === "prod")
  //   document.cookie = `activationRedirect=${relayState};expires=${expires}path=/;secure=true;domain=auth.hpe.com`;
  document.cookie = `activationRedirect=${cookie};path=/;secure=true;${
    isLocalDev() ? "domain=localhost" : "domain=.hpe.com"
  }`;
};

export const forgotPasswordActivateRedirectCookieUpdate = (cookie) => {
  const expires = cookieExpirationInSeconds(300);
  document.cookie = `activationRedirect=${cookie};expires=${expires};path=/;secure=true;${
    isLocalDev() ? "domain=localhost" : "domain=.hpe.com"
  }`;
};

export const cookieExpirationInDays = (days) =>
  new Date(Date.now() + 86400 * 1000 * days).toUTCString();

export const cookieExpirationInSeconds = (seconds) =>
  new Date(Date.now() + seconds * 1000).toUTCString();

export const dynamicHelpPage = (env, list = [], selectedLanguage) => {
  if (!selectedLanguage) selectedLanguage = "en";
  const langDetails = list.find((el) => el.value === selectedLanguage);
  // if (!langDetails || !langDetails?.helpLink) return helpUrlCommon;
  if (!langDetails || !langDetails?.helpLink)
    return env === "prod" ? helpURL_prod : helpURL_dev_itg;

  const helpPageUrl = langDetails?.helpLink;
  if (env === "dev") return helpPageUrl?.dev;
  if (env === "itg") return helpPageUrl?.itg;
  if (env === "prod") return helpPageUrl?.prod;
};

export const getChineseLang = (lang) => {
  lang = lang?.toLowerCase();
  if (
    lang === "zh_tw" ||
    lang === "zh-tw" ||
    lang === "zh-hk" ||
    lang === "zh_hk"
  ) {
    return "zh_tw";
  } else {
    return "zh_cn";
  }
};

export const fromURITest = (fromURI) => {
  const arr = ['auth-dev.hpe.com', 'auth-itg.hpe.com', 'auth.hpe.com'];
  const data = arr.find(el => decodeURIComponent(fromURI).includes(el));
  if (!data) return false;
  return true;
}

export const doubleDecoderUrl = (url) => {
  if (!url) return;
  const decodeUrlFirst = decodeURIComponent(url);
  const decodeUrlSecond = decodeURIComponent(decodeUrlFirst);
  return decodeUrlSecond;
};

export const doubleEncoderUrl = (url) => {
  if (!url) return;
  const encodeUrlFirst = encodeURIComponent(url);
  const encodeUrlSecond = encodeURIComponent(encodeUrlFirst);
  return encodeUrlSecond;
};

export const replaceString = (str, searchValue, replaceValue) => {
  if (!str) return;
  if (str.includes(searchValue)) {
    return str.replace(searchValue, replaceValue);
  }
  return str;
};

export const ieCompatibalityCheck=()=>{
  const browser = detect();
  var isIEcheck = false;
  var getIEversion = false;
  switch (browser && browser.name) {
    case "ie":
      isIEcheck = true;
      if(browser.version < "11") getIEversion =true;
      return[isIEcheck, getIEversion];
    default:
      return[isIEcheck, getIEversion];
  }
}

export const getChinaPolicyLink = (langInfo) => {
  const cc = langInfo.cc;
  if (cc === "cn" || cc === "tw") {
    return chinaPolicyLinkChinese;
  } else {
    return chinaPolicyLinkEnglish;
  }
}; 

export const setSessionStorage = (key, value) => {
  sessionStorage.setItem(key, value);
}; 

export const checkAbsoluteURL = (url) => {
  if (url.startsWith("https")) return true;
  return false;
};

export const backToSignInUsingRelayState = (relayState, history) => {
  window.location = 'https://www.hpe.com/';
  // window.location = 'https://www.hpe.com/in/en/home.html';
  // if (!relayState) history.replace("/");
  // else {
  //   if (checkAbsoluteURL(relayState)) window.location = decodeURL(relayState);
  //   else history.replace("/");
  // }
};

export const isAbsoluteURL = (url) => {
  try {
    new URL(decodeURL(url));
    return true;
  } catch (e) {
    return false;
  }
};

export const isRedirectURL = (str) => {
  const regex = /\/|%|https|http/;
  if (regex.test(str)) return true;
  else return false;
};

export const extractClientId = (str) => {
  const match = decodeURL(str).match(/client_id=([^&]+)/);
  const clientId = match ? match[1] : null;
  return clientId;
}

export const isHpeEmail = (str) => {
  const regex = /^[^@]+@hpe\.com$/;
  if (regex.test(str)) return true;
  else return false;
}