import React, { useState, useContext, useEffect } from 'react';
import { Box, Text, Button, FormField, CheckBox, Form } from 'grommet';
import AlertBanner from '../AlertBanner';
import AnchorGeneric from '../Anchor';
import { AuthContext } from '../../AuthContext';
import { hpeTermsOfUseUrl, hpePrivacyNoticeUrl } from '../../Helpers/urls';
import { getChinaPolicyLink, filterLanguageAndCountryCode } from '../../Helpers/common';

const initialFormState = {
    termsAndPrivacy: false,
    termsAndPrivacyChina: false,
};

const ConsentPage = (props) => {
    const [formValues, setFormValues] = useState(initialFormState);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const { state } = useContext(AuthContext);
    const { saveConsent, cancelConsent, consentPageError, showCancelButton } = props;
    const { HPE_privacy_notice, content, selectedLanguage, supportedLanguagesListDetails, signUp } = state;

    const { required_field_1 } = signUp || {};
    const {
        privacy_policy_accept,
        hpe_terms_of_use,
        privacy_policy_china,
        hpe_privacy_notice,
        china_policy_terms_1,
        china_policy_terms_2,
        china_policy_terms_3,
    } = HPE_privacy_notice || {};

    const { system_error, cancel_btn, save_btn } = content;

    const isSubmitDisabled = formValues.termsAndPrivacy && formValues.termsAndPrivacyChina;

    useEffect(() => {
        if (consentPageError.isError) {
          setDisableSaveButton(false);
        }
    }, [consentPageError]);

    const handleSubmit = (value, touched) => {
        setDisableSaveButton(true);
        saveConsent();
    };

    return (
        <Box flex gap="medium" width={'medium'} className="form-container" alignContent="center">
            {consentPageError.isError && <AlertBanner message={system_error} />}
            <Form
                validate="blur"
                value={formValues}
                messages={''}
                onChange={setFormValues}
                onSubmit={({ value, touched }) => handleSubmit({ value, touched })}>
                <FormField htmlFor="termsAndPrivacy">
                    <CheckBox
                        id="termsAndPrivacy"
                        name="termsAndPrivacy"
                        required
                        align="center"
                        label={
                            <Box alignContent="start">
                                <Text weight={'normal'} size="small">
                                    {privacy_policy_accept}{' '}
                                    <AnchorGeneric
                                        label={hpe_terms_of_use}
                                        href={hpeTermsOfUseUrl(
                                            filterLanguageAndCountryCode(
                                                supportedLanguagesListDetails,
                                                selectedLanguage
                                            )
                                        )}
                                    />
                                    {privacy_policy_china}
                                    <AnchorGeneric
                                        label={hpe_privacy_notice}
                                        href={hpePrivacyNoticeUrl(
                                            filterLanguageAndCountryCode(
                                                supportedLanguagesListDetails,
                                                selectedLanguage
                                            )
                                        )}
                                    />
                                    .*
                                </Text>
                            </Box>
                        }
                    />

                    <CheckBox
                        id="termsAndPrivacyChina"
                        name="termsAndPrivacyChina"
                        required
                        align="center"
                        label={
                            <Box alignContent="start">
                                <Text weight={'normal'} size="small">
                                    {china_policy_terms_1}
                                    <AnchorGeneric
                                        label={china_policy_terms_2}
                                        style={{display: 'inline'}}
                                        href={getChinaPolicyLink(
                                            filterLanguageAndCountryCode(
                                                supportedLanguagesListDetails,
                                                selectedLanguage
                                            )
                                        )}
                                    />
                                    {china_policy_terms_3}*
                                </Text>
                            </Box>
                        }
                    />
                </FormField>
                <Text size="small">{required_field_1}</Text>
                <Box align={'small'} margin={{ top: 'medium', bottom: 'small' }}>
                    <div className="profile-page-form-edit-footer">
                        <Button label={save_btn} primary type="submit" disabled={!isSubmitDisabled || disableSaveButton} />
                        {showCancelButton && (
                            <Button a11yTitle={cancel_btn} label={cancel_btn} onClick={() => cancelConsent()} />
                        )}
                    </div>
                </Box>
            </Form>
        </Box>
    );
};

export default ConsentPage;
